const initialState = {
  role: { name: "Paciente", isActive: true },
  product: "V4H agenda",
  stringSearch: '',
};

const CURRENT_ROLE = 'CURRENT_ROLE';
const CURRENT_PRODUCT = 'CURRENT_PRODUCT';
const STRING_SEARCH = 'STRING_SEARCH';

export const currentProduct = product => ({
  type: CURRENT_PRODUCT, product
});

export const currentRole = role => ({
  type: CURRENT_ROLE, role
});

export const setStringSearch = str => ({
  type: STRING_SEARCH, str
});

export default (state = initialState, action) => {
  switch (action.type) {
    case CURRENT_ROLE:
      return { ...state, role: action.role };
    case CURRENT_PRODUCT:
      return { ...state, product: action.product };
    case STRING_SEARCH:
      return { ...state, stringSearch: action.str };
    default:
      return state;
  }
};